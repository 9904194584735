.m-filter-list {
	display: flex;
	flex-flow: row wrap;


	// module
	.m-button-list {
		display: flex;

		margin: 0 2px 8px 25px;
	}


	&.-c-force-rows {

		// module
		.m-button-list {
			flex-basis: 100%;
		}
	}

	&.-align-left {
		justify-content: flex-start;


		// module
		.m-button-list {
			justify-content: flex-start;


			&__container {
				justify-content: flex-start;
			}
		}
	}

	&.-align-right {
		justify-content: flex-end;


		// module
		.m-button-list {
			justify-content: flex-end;


			&__container {
				justify-content: flex-end;
			}
		}
	}
}