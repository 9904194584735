.m-area-list {
	$c: &;

	position: relative;

	display: flex;
	flex-flow: row wrap;

	margin: 5px 0 10px 0;


	&__container {
		flex: 0 0 auto;

		width: 100%;
		padding: 5px 10px 10px 5px;


		&.-width-full {
			@extend %area-width-full;


			// component
			.c-area-box {
				&__bg {
					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}


	&.-columns-2 {

		#{$c}__container {
			flex: 0 1 50%;

			max-width: 50%;


			&.-width-full {
				@extend %area-width-full;
			}
		}
	}

	&.-columns-small {
		#{$c}__container {
			flex: 0 1 200px;

			max-width: 200px;
		}
	}
}


/**
 * Placeholder for shared area widths.
 */
%area-width-full {
	flex: 1 0 100%;

	width: 100%;
	max-width: 100%;
}