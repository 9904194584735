.m-page-desc-container {
	display: flex;


	&__icon {
		flex: 0 1 auto;

		margin-right: 10px;


		i {
			font-size: 40px;
			line-height: 1.1;
			text-shadow: 1px 1px 1px #000;

			color: #B0A087;
		}
	}

	&__inner {
		flex: 1 1 auto;
	}

	&__side {

	}


	// component
	.c-page-desc {

	}
}