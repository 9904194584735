.m-sb-challenges {
	$c: &;

	border-bottom: 1px solid #CFBC90;


	&__row {
		position: relative;

		display: flex;
		flex-flow: column nowrap;

		border-bottom: 1px solid #BFAC7B;

		background-color: #c5b384;


		&.-status-recent {
			border-left-color: #F7E3B8;
			border-bottom-color: #D2C591;

			background-color: #CCBA8A;


			#{$c}__date {
				display: block;
			}
		}

		&.-status-new {
			border-left-color: #F7E3B8;
			border-bottom-color: #E5D8A2;

			background-color: #D8C798;


			#{$c}__date {
				display: block;
			}


			&.-date-fresh {
				#{$c}__date {
					color: #B36001;
				}
			}
		}

		&.-date-fresh {
			#{$c}__date {
				display: block;
			}
		}
	}

	&__data {
		display: flex;
		align-items: center;

		height: 34px;
	}

	&__inner {
		flex: 1 1 auto;

		display: flex;
		align-items: center;

		min-width: 0;
		height: 100%;

		cursor: pointer;
	}

	&__date {
		position: relative;

		display: none;
		margin-top: -9px;
		padding: 3px 2px;

		font-size: 0.575rem;
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;
		text-shadow: 1px 1px 0 #ECDFB6;

		color: #968047;
	}

	&__rating {
		flex: 0 0 23px;
		width: 23px;

		padding: 0 1px;

		font-size: 0.675rem;
		font-weight: 700;
		text-align: right;
		letter-spacing: -1px;
		text-shadow: 1px 1px 0 #e4d9ba;


		&.-result-win {
			color: #29701b;
		}

		&.-result-loss {
			color: #911c1c;
		}
	}

	&__rank {
		flex: 0 0 30px;
		width: 30px;

		margin: 0 0 0 3px;
	}

	&__name {
		position: relative;

		flex: 1 1 auto;

		display: flex;
		align-items: center;

		min-width: 0;
		margin: 0 5px 0 0;
		padding: 0 1px 0 1px;

		text-shadow: 1px 1px 0 #D8CFB3;

		color: #43290a;


		span {
			border-bottom: 1px solid #817260;

			font-size: 0.7rem;
			font-weight: 700;
			line-height: 1.2;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}

	&__challenge {
		position: relative;
		top: 1px;

		margin: 0 5px 0 5px;


		&:before {
			content: '\e83b';
		}


		&.-icon-challenge-return {
			font-size: 24px;


			&:before {
				position: relative;
				top: -2px;
				left: 3px;

				content: '\e83a';
			}
		}
	}

	&__replay {
		margin: 0 0 0 5px;

		text-align: center;


		&:before {
			content: '\e81b';
		}
	}

	&__challenge,
	&__replay {
		flex: 0 0 30px;
		width: 30px;

		font-size: 18px;
		text-align: center;
		text-shadow: 1px 1px 1px #f4ead3,
		0 0 0 #000;

		color: rgba(#9b792a, 0.8);

		cursor: pointer;


		&:hover {
			color: rgba(#825d07, 0.8);
		}


		&:before {
			@extend %font-nm;
		}
	}


	&:first-child {
		#{$c}__data {
			margin-top: 2px;
		}
	}
}