.m-sb-recipes {
	$c: &;

	border-bottom: 1px solid #CFBC90;


	&__category {
		height: 28px;

		border-top: 1px solid #e2dab9;
		border-bottom: 2px solid #ad9865;

		text-align: center;

		background-color: #ceb981;


		span {
			font-size: 0.65rem;
			text-transform: uppercase;
			text-shadow: 1px 1px 0 #e6d9ac;

			color: #8a702d;
		}
	}

	&__row {
		position: relative;

		display: flex;
		align-items: center;

		height: 26px;

		border-bottom: 1px solid #BFAC7B;

		background: linear-gradient(to right, #af9c68, rgba(#bdaa79, 0));

		cursor: pointer;


		&:hover {
			background-color: #af9c68;
		}
	}

	&__icon {
		flex: 0 0 25px;
		width: 25px;

		margin: 0 2px 0 5px;

		text-align: center;

		// need to overwrite helper colors
		background-color: transparent !important;
		border-color: transparent !important;


		&:before {
			@extend %font-nm;

			content: none;

			font-size: 14px;
			text-shadow: 1px 1px 0 #000;
		}
	}

	&__pic {
		position: relative;

		flex: 0 0 18px;
		width: 18px;
		height: 18px;

		margin: 0 5px 0 9px;

		border: 1px solid #c3b58d;
		border-radius: 5px;

		overflow: hidden;


		img {
			position: absolute;

			width: 16px;
			height: 16px;
		}
	}

	&__name {
		position: relative;

		flex: 1 1 auto;

		display: flex;
		align-items: center;

		min-width: 0;
		margin: 0 15px 0 0;
		padding: 0 1px 0 1px;

		text-shadow: 1px 1px 0 #ccc1a0;

		color: #46321a;


		span {
			border-bottom: 1px solid #9a856c;

			font-size: 0.7rem;
			font-weight: 700;
			line-height: 1.2;
			text-overflow: ellipsis;
			white-space: nowrap;

			overflow: hidden;
		}
	}

	&__info {
		margin-right: 5px;

		&:before {
			content: '\e881';
		}
	}

	&__known {
		&:before {
			content: '\e841';
		}
	}

	&__info,
	&__known {
		position: relative;
		top: 1px;

		flex: 0 0 20px;
		width: 20px;

		font-size: 12px;
		text-align: center;
		text-shadow: 1px 1px 1px #f4ead3;
		color: #967b3d;



		&:hover {
			color: rgba(#825d07, 1);
		}


		&:before {
			@extend %font-nm;
		}
	}


	&:first-child {
		#{$c}__data {
			margin-top: 2px;
		}
	}
}