/**
 * Variables.
 */
$history: '.m-progress-history';
$replay: '.m-progress-replay';





.m-progress {
	position: relative;

	min-height: 300px;
	margin: 10px 5px 10px 10px;


	&__structure {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&__teams {
		position: relative;

		display: flex;
		flex-flow: column-reverse nowrap;

		padding: 40px 0;
	}


	&.-height-full {
		min-height: 0;
	}
}


/**
 * Difficulty mode presentation section.
 */
.m-progress-diffs {
	$diffs: &;

	position: relative;

	display: flex;

	height: 100%;


	&__outer {
		flex: 1 0 0%;

		display: flex;
		flex-flow: column nowrap;


		&:not(:first-child) {
			#{$diffs}__diff {
				border-left: 1px solid #cc9677;
			}
		}
	}


	&__diff {
		flex: 0 0 20px;

		position: relative;

		font-size: 0.8rem;
		text-align: center;
		text-transform: uppercase;
		text-shadow: 1px 1px 1px #000;

		height: 20px;

		border-left: 1px solid transparent;
		border-bottom: 1px solid #cc9677;

		color: #cc9677;


		&:before {
			content: '';

			position: absolute;
			bottom: -3px;
			left: 0;

			width: 100%;
			height: 2px;

			background-color: #000;
		}
	}

	&__areas {
		position: relative;

		flex: 1 0 auto;

		display: flex;
		height: 100%;
		margin: 0;
		padding: 0;


		li {
			position: relative;

			flex: 1 0 0%;

			display: block;
			margin: 0;
			padding: 0;


			&:before {
				content: '';

				position: absolute;
				top: 0;
				left: 0;

				width: 1px;
				height: 100%;

				background-color: #30241d;
			}


			&:nth-child(2n) {
				#{$history}__top {
					height: 105px;
				}
			}

			&:nth-child(4n+3) {
				#{$history}__top {
					height: 120px;
				}
			}


			&:hover {
				#{$history}__top {
					&:before {
						display: block;
					}
				}

				#{$replay} {
					border-color: #000;

					color: #f7d3c5;


					span {
						display: block;
					}
				}
			}
		}
	}
}


/**
 * Area progress history icons section.
 */
.m-progress-history {
	position: absolute;
	top: 0;
	right: -15px;

	display: flex;
	flex-flow: column nowrap;

	width: 30px;


	&__top {
		position: relative;

		flex: 1 0 auto;

		height: 90px;

		z-index: 1;


		&:before {
			content: '';

			position: absolute;
			top: 0;
			right: 0;
			bottom: -8px;
			left: 0;

			display: none;
			width: 2px;
			margin: 0 auto;

			background-color: #000;
		}
	}

	&__inner {
		position: relative;

		flex: 0 1 auto;

		text-align: center;

		z-index: 2;
	}


	// component
	.c-item {
		margin-top: 8px;


		// overwrite with regular margin for non-first children
		~ .c-item {
			margin-top: 2px;
		}


		&.-c-forge {
			margin-top: 8px;

			~ .c-item {
				margin-top: 2px;
			}
		}
	}
}


/**
 * Area progress replay box.
 */
.m-progress-replay {
	position: relative;
	right: 4px;

	display: inline-flex;
	align-items: center;

	float: right;
	padding: 2px 4px 3px 4px;

	border-top: 2px solid transparent;
	border-radius: 4px;

	line-height: 1.2;
	white-space: nowrap;
	text-shadow: 1px 1px 1px #000;

	color: #c19f92;

	background-color: #563a30;

	cursor: pointer;


	span {
		padding-right: 4px;

		font-size: 0.6rem;
		text-align: right;
		white-space: nowrap;

		overflow: hidden;

		display: none;
	}

	i {
		font-size: 12px;
	}

	&__icon {
		font-size: 12px;


		&:before {
			@extend %font-nm;

			content: '\e81b';
		}
	}
}


/**
 * Team section.
 */
.m-progress-team {
	$c: &;

	position: relative;

	height: 30px;

	z-index: 1;


	&__bar {
		position: relative;

		width: 100%;
		height: 18px;
		margin-top: 6px;

		border: 2px solid #000;
		border-left: 0;
		border-right: 0;

		background-color: #795936;


		&:after {
			content: '';

			position: absolute;
			bottom: 0;
			left: 0;

			height: 5px;
			width: 100%;

			background-color: #523d27;
		}
	}


	// component
	.c-user-box {
		position: absolute;
		top: 0;
		right: 2px;

		width: 200px;


		&.-c-avatar-only {
			width: auto;
		}
	}


	&:hover {
		z-index: 2;
	}


	&.-place-1 {
		#{$c}__bar {
			background-color: #b79721;


			&:after {
				background-color: #827019;
			}
		}


		// component
		.c-user-box {
			border-color: #b79721;
		}
	}

	&.-place-2 {
		#{$c}__bar {
			background-color: #bfbfbf;


			&:after {
				background-color: #888;
			}
		}


		// component
		.c-user-box {
			border-color: #bfbfbf;
		}
	}

	&.-place-3 {
		#{$c}__bar {
			background-color: #ad7032;


			&:after {
				background-color: #7b5720;
			}
		}


		// component
		.c-user-box {
			border-color: #ad7032;
		}
	}
}





/**
 * Related: Rewards section.
 */
.m-progress-rewards {
	display: flex;
	flex-flow: column nowrap;

	padding: 0 0 10px 15px;


	&__place {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		margin-bottom: -7px;
	}

	&__icon {
		flex: 0 0 35px;

		width: 35px;

		text-align: center;

		color: #795936;


		&:before {
			@extend %font-nm;

			content: '\e83d';

			font-size: 24px;
			text-shadow: 1px 1px 1px #000;
		}


		&.-place-1 {
			color: #b79721;
		}

		&.-place-2 {
			color: #999999;
		}

		&.-place-3 {
			color: #ad7032;
		}
	}


	// component
	.c-item {

	}
}