.m-card-container {
	position: relative;

	display: flex;
	flex-flow: row nowrap;


	&__items {
		flex: 0 0 auto;
		align-self: flex-start;

		display: flex;
		flex-flow: column nowrap;

		margin-left: 2px;


		// component
		.c-item {
			margin-bottom: 2px;


			&:last-child {
				margin-bottom: 0;
			}
		}


		// only affect with size-s card sibling
		.c-card.-size-s ~ & {

			// component
			.c-item {

				// 4 or more items, build upwards
				&:nth-child(1):nth-last-child(4) {
					margin-top: -13px;
				}
			}
		}
	}

	&__details {
		flex: 0 0 auto;

		margin-left: 2px;


		// component
		&.c-item-details {
			$item: '.c-item-details';

			// only affect with size-s card sibling
			.c-card.-size-s ~ & {

				#{$item}__stats {

					// 4 stats, narrow it
					span:first-child:nth-last-child(4),
					span:first-child:nth-last-child(4) ~ span {
						line-height: 1.1;
					}
				}
			}
		}
	}


	// component
	.c-card {
		flex: 1 0 auto;
		align-self: flex-end;
	}
}
