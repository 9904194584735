.m-bar-container {
	$c: &;

	position: relative;


	&:before {
		content: '';

		position: absolute;
	}

	&:after {
		content: '';

		position: absolute;
	}


	&.-type-genjutsu {
		&:before {
			background: url('#{$gfx-path}/themes/#{$theme}/shared/bar_genjutsu_bg_left.png') 0 0 no-repeat;
		}

		&:after {
			background: url('#{$gfx-path}/themes/#{$theme}/shared/bar_genjutsu_bg_right.png') 0 0 no-repeat;
		}
	}

	&.-type-health {
		&:before {
			background: url('#{$gfx-path}/themes/#{$theme}/shared/bar_health_bg_left.png') 0 0 no-repeat;
		}

		&:after {
			background: url('#{$gfx-path}/themes/#{$theme}/shared/bar_health_bg_right.png') 0 0 no-repeat;
		}
	}


	&.-size-m {
		&.-type-health {
			&:before {
				top: -12px;
				left: -11px;

				width: 59px;
				height: 39px;

				background-size: 59px 39px;
			}

			&:after {
				top: -12px;
				right: -21px;

				width: 59px;
				height: 37px;

				background-size: 59px 37px;
			}


			// component
			.c-bar {
				height: 22px;
				border-width: 1px;


				&__text {
					right: -10px;
				}
			}
		}

		&.-type-genjutsu {
			&:before {
				top: -15px;
				left: -10px;

				width: 122px;
				height: 70px;
			}

			&:after {
				top: -14px;
				right: -12px;

				width: 118px;
				height: 70px;
			}


			// component
			.c-bar {
				height: 26px;
			}
		}
	}

	&.-size-l {
		&.-type-health {
			&:before {
				top: -30px;
				left: -10px;

				width: 118px;
				height: 78px;
			}

			&:after {
				top: -32px;
				right: -13px;

				width: 117px;
				height: 74px;
			}


			// component
			.c-bar {
				height: 34px;
			}
		}
	}
}