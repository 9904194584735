.m-damage-table {
	position: relative;

	padding: 10px;


	table {
		position: relative;

		display: table;
		table-layout: fixed;
		border-collapse: collapse;
		border-spacing: 0;

		width: 100%;

		background-color: #2B1D0E;

		z-index: 1;
	}

	thead {
		tr {
			border-bottom: 1px solid #4c3a23;

			text-shadow: 1px 1px 0 #000;

			background-color: #211507;


			em {
				color: #9a6c39;
			}

			b {
				font-size: 0.7rem;

				color: #F19F58;
			}
		}

		td {
			&.-c-cur {
				background-color: #352818;
				color: #c3a684;
			}
		}
	}

	tr {

		&.-c-numbers {
			td {
				height: 30px;

				font-size: 0.65rem;

				color: #b3926e;


				em {
					color: #c78d4d;
					font-size: 0.7rem;
				}
			}
		}
	}

	td {
		height: 24px;
		vertical-align: middle;

		font-size: 0.7rem;
		text-align: center;

		color: #6B553D;


		&.-c-name {
			width: 90px;
			padding-right: 10px;

			font-size: 0.65rem;
			text-align: right;
			text-overflow: ellipsis;
			white-space: nowrap;

			color: #967653;

			overflow: hidden;


			b {
				font-size: 0.7rem;

				color: #F19F58;
			}
		}

		&.-c-cur {
			background-color: #402d1b;
		}
	}
}