.m-lineup-battle {
	$m: &;

	width: 200px;
	height: 180px;


	&__summon {
		position: absolute;
		top: 50px;
		left: 5px;

		z-index: 1;
	}

	&__summon-extra {
		position: absolute;
		top: 159px;
		left: 5px;

		display: flex;
		flex-flow: row nowrap;

		z-index: 1;


		// component
		.c-card {
			margin: 0 3px 0 0;
		}
	}

	&__ninjas {
		position: absolute;
		top: 0;
		left: 30px;

		width: 159px;
		height: 100%;

		z-index: 2;
	}


	&.-type-team {

		#{$m}__ninjas {


			// component
			.c-card {
				position: absolute;


				&:nth-child(1) {
					top: 0;
					left: 0;
				}

				&:nth-child(2) {
					top: 0;
					left: 54px;
				}

				&:nth-child(3) {
					top: 0;
					left: 108px;
				}

				&:nth-child(4) {
					top: 59px;
					left: 27px;
				}

				&:nth-child(5) {
					top: 59px;
					left: 81px;
				}

				&:nth-child(6) {
					top: 118px;
					left: 54px;
				}
			}
		}
	}


	&.-type-linear {
		width: 246px;

		#{$m}__ninjas {
			display: flex;
			flex-flow: row wrap;
			align-items: flex-start;

			width: auto;
			height: auto;


			// component
			.c-card {
				position: relative;

				flex: 0 0 auto;

				margin: 0 3px 3px 0;
			}
		}
	}

	&.-side-left {
		#{$m}__summon-extra {

			// component
			.c-card {
				$card: '.c-card';

				&.-size-s {

					// position on bottom
					#{$card}__lvl {
						top: auto;
						right: auto;
						left: 3px;
						bottom: -2px;
					}

					// position on left
					#{$card}__exp-gain {
						right: auto;
						left: 4px;
					}
				}
			}
		}
	}

	&.-side-right {

		#{$m}__ninjas {
			right: 30px;
			left: auto;
		}

		#{$m}__summon {
			right: 5px;
			left: auto;
		}

		#{$m}__summon-extra {
			right: 5px;
			left: auto;

			flex-direction: row-reverse;
			justify-content: flex-start;


			// component
			.c-card {
				$card: '.c-card';

				margin: 0 0 0 3px;


				// position on bottom
				#{$card}__lvl {
					right: 4px;
					left: auto;
				}
			}
		}


		// component
		.c-card {
			$card: '.c-card';


			&.-size-m {

				// position on right
				#{$card}__lvl {
					right: 4px;
					left: auto;
				}

				// position on right
				#{$card}__exp-gain {
					right: 4px;
					left: auto;
				}
			}
		}
	}


	// component
	.c-card {
		$card: '.c-card';


		&:not(.-c-empty) {
			#{$card}__pos {
				display: none;
			}
		}

		&.-c-empty {
			border-color: #533d23;
		}

		&.-size-s {
			flex: 0 0 56px;

			height: 56px;


			// enable inner shadow
			#{$card}__pic {
				&:before {
					content: '';
				}
			}

			// position on bottom
			#{$card}__lvl {
				top: auto;
				right: 3px;
				bottom: -2px;
			}
		}

		&.-size-m {

			// position on bottom
			#{$card}__lvl {
				top: auto;
				right: auto;
				left: 3px;
				bottom: -2px;
			}

			// position on bottom
			#{$card}__exp-gain {
				top: 47px;
				right: auto;
				left: 4px;
			}
		}
	}
}
