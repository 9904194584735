.m-item-container {
	display: flex;
	align-items: flex-start;


	// component
	.c-item {

	}

	// component
	.c-button {
		&.-color-cancel {
			span {
				text-transform: none;
			}
		}
	}


	&.-size-s {

		// component
		.c-item {
			width: 85px;

			margin-top: 1px;
		}

		// component
		.c-button {
			height: 27px;
			width: 66px;
		}
	}
}