.m-levels-container {
	$c: &;

	position: relative;

	display: flex;
	width: 100%;


	&__level {
		flex: 1 0 0%;

		position: relative;

		display: flex;
		flex-flow: column nowrap;

		padding: 1px;


		&.-c-achieved {
			#{$c}__points {
				// colors only
			}
		}
	}

	&__cur {
		position: absolute;
		top: -14px;

		width: 80px;
		margin-right: -40px;
		padding: 0 1px;

		font-size: 0.7rem;
		letter-spacing: -1px;
		text-align: center;

		color: #e0afaf;

		z-index: 1;


		b {
			position: relative;

			font-size: 0.9rem;
		}


		&.-align-right {
			margin-right: 0;

			text-align: right;
		}

		&.-align-left {
			margin-right: -80px;

			text-align: left;
		}
	}

	&__goal {
		font-size: 0.55rem;
		text-align: right;

		color: #a96d6d;
	}

	&__points {
		font-size: 0.55rem;
		font-weight: 700;
		text-align: right;

		color: #484763;
	}


	// component
	.c-bar {
		width: auto;
		height: 10px;

		// cannot extend .c-bar.-border-hidden, copy pasting...
		padding: 0;

		border: 0;
		border-radius: 0;

		// reset gradient background TODO: why?
		//background: #4e2f28;


		&:before {
			content: none;
		}
	}


	&.-type-achievement {
		#{$c}__goal {
			color: #706da9;
		}

		#{$c}__points {
			color: #484763;
		}

		#{$c}__cur {
			color: #b0afe0;
		}

		#{$c}__level {
			&.-c-achieved {
				#{$c}__points {
					color: #9b9ace;
				}
			}
		}


		// component
		.c-bar {
			background: #23233a;
		}
	}

	&.-type-themed-inactive {
		#{$c}__goal {
			color: #6b5341
		}

		#{$c}__points {
			color: #413328;
		}

		#{$c}__cur {
			color: #a38066;
		}
	}

	&.-type-bloodline {
		#{$c}__goal {
			color: #a96d6d;
		}

		#{$c}__cur {
			color: #e0afaf;
		}


		// component
		.c-bar {
			background: #4e2f28;
		}
	}
}