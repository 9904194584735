.m-ninja-details {
	$c: &;

	position: relative;

	display: flex;

	min-height: 226px;


	&__column {
		flex: 1 1 100%;

		display: flex;
		justify-content: center;


		&.-side-left {
			justify-content: flex-end;

			margin-right: auto;
		}

		&.-c-squeezed {
			flex: 0 1 auto;
		}

		&.-side-right {
			justify-content: flex-start;

			margin-left: auto;
		}
	}

	&__graph {
		position: relative;
		top: -50px;
		right: 5px;


		// component
		.c-stats-list {
			position: relative;
			top: 70px;
			right: 15px;

			width: 210px;
		}
	}

	&__card {
		position: relative;
		top: -70px;
	}

	&__options {
		width: 260px;
		padding: 15px 15px 15px 20px;
	}


	&.-c-lowered {
		#{$c}__graph {
			top: auto;


			// component
			.c-stats-list {
				top: 10px;
			}
		}

		#{$c}__card {
			top: -45px;
		}
	}
}