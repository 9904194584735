.m-button-container {
	position: relative;

	display: inline-block;


	&.-align-center {
		width: 100%;

		text-align: center;
	}

	&.-align-right {
		width: 100%;

		text-align: right;
	}


	// component
	.c-button {
		display: inline-flex;
	}

	// component
	.c-notification {
		position: absolute;
		top: -14px;
		right: -20px;
	}
}