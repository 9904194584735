.m-quickmenu {
	position: relative;

	display: flex;

	margin: 3px 0;


	&__cards {
		// .m-quickmenu-cards
	}

	&__nav {
		// .m-quickmenu-nav
	}



}


/**
 * Cards container section.
 */
.m-quickmenu-cards {
	position: relative;

	flex: 1 1 auto;

	min-height: 73px;
	margin: 7px 10px 0 10px;


	&__control {
		position: absolute;
		top: 0;

		display: none;
		align-items: center;

		width: 23px;
		height: 67px;

		color: #CA965D;

		cursor: pointer;
		z-index: 3;


		&:before {
			@extend %font-nm;

			position: relative;

			font-size: 30px;
			text-shadow: 1px 1px 2px #000,
						-1px -1px 1px #000;
		}


		&.-side-left {
			left: 0;

			background: linear-gradient(to right, rgb(26, 18, 11), rgba(#261a11, 0));


			&:before {
				content: '\e845';

				right: 10px;
			}
		}

		&.-side-right {
			right: 0;

			width: 30px;

			background: linear-gradient(to left, rgb(26, 18, 11), rgba(#261a11, 0));

			&:before {
				content: '\e844';

				left: 10px;
			}
		}

		&.-c-active {
			display: flex;
		}


		&:hover {
			color: #F4B774;
		}
	}

	&__outer {
		position: relative;

		width: 100%;
		height: 100%;

		overflow: hidden;
	}

	&__inner {
		position: absolute;
		left: 0;

		display: flex;

		width: 5000px;
		padding: 2px;


		// component
		.c-card {
			margin-right: 2px;

			cursor: pointer;
		}
	}
}


/**
 * Section buttons section.
 */
.m-quickmenu-nav {
	flex: 0 0 auto;

	margin: 0 3px 0 50px;


	&__buttons {
		display: flex;
		flex-flow: row-reverse nowrap;

		max-height: 100%;


		// component
		.c-button {
			display: flex;
		}
	}

	&__column {
		// only used to simulate columns since column-reverse is not working properly
	}
}