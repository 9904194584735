.m-card-list {
	display: flex;
	flex-flow: row wrap;


	// component
	.c-card {
		margin: 0 8px 8px 0;

		cursor: pointer;
	}


	&.-type-selection {

		// component
		.c-card {
			margin: 0 15px 15px 0;

			border-color: #463420;


			&.-c-sel {
				border-color: #FB9B31;
			}
		}
	}
}