.m-exp-container {
	$c: &;

	position: relative;


	&__lvl {
		margin-right: 3px;
		margin-bottom: -8px;

		text-align: right;

		z-index: 2;
	}

	&__lvl-text {
		margin-right: -2px;

		font-size: 0.8rem;

		color: #af9d7f;
	}

	&__lvl-nr {
		font-size: 1.5rem;
		letter-spacing: -1px;

		color: #d2b98e;
	}

	&__bar {
		padding: 3px;

		border: 1px solid #655849;
		border-radius: 2px;

		background-color: #130c06;
	}


	// component
	.c-exp {

		&.-size-l {
			height: 14px;
		}
	}
}