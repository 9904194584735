.m-lineup {
	position: relative;

	display: flex;
	flex-flow: row nowrap;


	// component
	.c-card {
		$card: '.c-card';

		margin-left: -2px;


		&:nth-child(1),
		&:nth-child(4) {
			z-index: 3;
		}

		&:nth-child(2) {
			z-index: 2;
		}

		&:nth-child(4),
		&:nth-child(6),
		&:nth-child(7) {
			margin-left: 9px;
		}

		&:not(.-c-empty) {
			cursor: pointer;

			&:nth-child(1),
			&:nth-child(4) {
				z-index: 5;
			}

			&:nth-child(2),
			&:nth-child(5) {
				z-index: 4;
			}

			&:nth-child(3) {
				z-index: 3;
			}
		}


		.-status-selectable & {
			border-color: $m-lineup-selectable-card-border-color;

			cursor: pointer;
		}

		.-status-editing & {
			&__offpos {
				display: block;
			}

			&:not(.-c-empty) {
				cursor: pointer;

				&:hover {
					border-color: $m-lineup-editable-card-hover-border-color;
				}
			}
		}

		.highlight-pos-1 & {
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) {
				border-color: $m-lineup-highlight-valid-pos-card-border-color;
			}
		}

		.highlight-pos-2 & {
			&:nth-child(4),
			&:nth-child(5) {
				border-color: $m-lineup-highlight-valid-pos-card-border-color;
			}
		}

		.highlight-pos-3 & {
			&:nth-child(6) {
				border-color: $m-lineup-highlight-valid-pos-card-border-color;
			}
		}

		.highlight-pos-4 & {
			border-color: $m-lineup-highlight-invalid-pos-card-border-color;

			pointer-events: none;

			&:nth-child(7) {
				border-color: $m-lineup-highlight-valid-pos-card-border-color;

				pointer-events: auto;
			}
		}

		.highlight-pos-1 &,
		.highlight-pos-2 &,
		.highlight-pos-3 & {
			&:nth-child(7) {
				border-color: $m-lineup-highlight-invalid-pos-card-border-color;

				pointer-events: none;
			}
		}


		&.-size-s {
			color: #fff;
			&.-c-empty {
				min-height: 65px;


				#{$card}__pos {
					top: 4px;
				}
			}
		}
	}
}