.m-tooltip-big {
	$c: &;

	position: relative;

	margin: 7px; // to prevent the pseudo borders from pushing outside screen
	padding: 1px;

	border: 2px solid #D4BB93;
	border-radius: 9px;

	background-color: #000;


	&:before {
		content: '';

		position: absolute;
		top: -7px;
		left: -7px;
		right: -7px;
		bottom: -7px;

		border: 3px solid rgba(#000, 0.2);
		border-radius: 14px;
	}

	&:after {
		content: '';

		position: absolute;
		top: -4px;
		left: -4px;
		right: -4px;
		bottom: -4px;

		border: 2px solid rgba(#000, 0.5);
		border-radius: 11px;
	}


	&__outer {
		position: relative;

		width: 490px;
		min-height: 460px;

		border: 2px solid #6B5A3D;
		border-radius: 6px;

		text-shadow: 1px 1px 0 #000;

		background-color: #332518;

		z-index: 1;


		&:before {
			content: '';

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			border: 3px solid rgba(#2b1f15, 0.7);
			border-radius: 5px;
		}
	}

	&__challenge {

		// component
		&.c-button {
			position: absolute;
			top: 8px;
			right: 20px;

			min-width: 90px;
		}
	}

	&__lineup-name {
		position: absolute;
		top: 40px;
		right: 0;
		left: 0;

		font-size: 0.6rem;
		line-height: 1;
		text-align: center;
		text-shadow: 1px 1px 1px #000;

		color: #9e8363;


		b {
			color: #c79e6f;
		}
	}

	&__lineup {
		display: flex;
		flex-flow: row wrap;

		padding: 0 2px 85px 20px;

		z-index: 2;


		// module
		.m-card-container {
			$m: '.m-card-container';

			flex: 1 0 33%;

			width: 33%;
			padding: 20px 0 0 0;


			&__items {
				max-width: 85px;
			}

			&__details {
				max-width: 85px;


				&.-c-hidden {
					display: none;

					margin: 0 2px 0 0;
				}
			}

			&.-size-l {
				padding-bottom: 20px;

				#{$m}__items {
					align-self: flex-end;
				}
			}
		}


		// component
		.c-item {
			width: 85px;
		}

		// component
		.c-item-details {
			right: auto;
			bottom: auto;

			width: 85px;
			height: 100%;


			&__genjutsu {
				position: absolute;
				right: 3px;
				bottom: 1px;
			}
		}

		// component
		.c-card {
			&.-c-empty {
				height: 89px;
			}
		}
	}

	&__lineup-summons {
		flex: 1 0 100%;

		display: flex;
		flex-flow: row-reverse nowrap;

		width: 100%;

		margin-top: 20px;
		padding-right: 7px;


		// module
		.m-card-container {
			$m: '.m-card-container';

			flex: 0 0 auto;

			width: auto;
			min-width: 0;

			margin-right: 2px;
			padding: 0;

			cursor: pointer;


			&__details {
				&.-c-hidden {
					display: none;

					margin: 0 2px 0 0;
				}
			}

			&:first-child {
				margin-right: 10px;
				margin-left: 10px;
			}


			&:hover {
				#{$m}__details {
					&.-c-hidden {
						position: absolute;
						right: 51px;
						bottom: 0;

						display: block;

						z-index: 4;
					}
				}
			}
		}

		// component
		.c-card {
			&.-c-empty {
				height: 65px;
			}
		}
	}

	&__user {
		position: absolute;
		bottom: 20px;
		left: 20px;


		// component
		.c-user-box {
			width: 200px;
		}
	}

	&__divider {
		width: 100%;
		height: 19px;
		margin-top: 5px;

		background: url('#{$gfx-path}/themes/#{$theme}/shared/divider_tooltip_1_big.png') center center no-repeat;
	}

	&__bg-bot {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;

		height: 180px;

		border-radius: 0 0 6px 6px;

		overflow: hidden;

		z-index: -1;


		&:before {
			content: '';

			position: absolute;
			top: -1px;

			width: 100%;
			height: 80px;

			background: linear-gradient(#332518, rgba(#332518,0));
		}

		&:after {
			content: '';

			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			background: rgba(#332518, 0.5);
		}


		img {
			width: 100%;
		}
	}


	// component
	.c-overlay {
		border-radius: 6px;
	}
}



/**
 * Team section in top.
 */
.m-tooltip-big-team {
	position: relative;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	height: 30px;
	margin: 10px 120px 5px 5px;


	&__rating {
		width: 50px;
		min-width: 50px;

		font-size: 0.8rem;
		text-align: right;
		text-shadow: 1px 1px 1px #000;

		color: #BD9D78;
	}

	&__rank {
		width: 40px;
		min-width: 40px;
	}

	&__name {
		flex: 1 0 auto;

		font-size: 0.9rem;
		font-weight: 600;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-shadow: 1px 1px 2px #000;

		overflow: hidden;


		a {
			padding: 0 1px;

			text-decoration: underline;

			color: #EDC896;
		}
	}
}
