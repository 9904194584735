.m-lineup-list {
	position: relative;

	display: inline-flex;
	flex-flow: row nowrap;

	border: 1px solid #5a4630;
	border-radius: 4px;

	background-color: #291d12;


	.c-filter-button {
		min-width: 90px;
		margin: 0 -1px 0 -1px;


		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}