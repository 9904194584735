.m-sb-toplist {
	$c: &;

	padding: 10px;


	&__row {
		position: relative;

		display: flex;
		margin: 0 0 4px 0;


		&.-size-l {
			&:first-child {
				#{$c}__nr {
					top: 0;
				}
			}


			#{$c}__nr {
				position: absolute;
				top: 10px;
				right: 2px;


				b {
					font-size: 1.1rem;
					letter-spacing: -1px;
				}
			}


			// component
			.c-user-box {
				width: 100%;
			}
		}
	}

	&__nr {
		flex: 1 0 auto;

		position: relative;

		align-self: center;

		margin-left: 10px;

		line-height: 1.2;
		text-align: right;
		text-shadow: 1px 1px 1px #F9F2D8;


		span {
			position: relative;
			top: -2px;

			display: block;

			font-size: 0.5rem;
			font-weight: 700;
			text-transform: uppercase;
			white-space: nowrap;

			color: #672504;

		}

		b {
			display: block;

			font-size: 0.8rem;
			text-shadow: 1px 1px 1px #F9F2D8;

			color: #672504;
		}
	}


	// component
	.c-user-box {
		flex: 1 1 auto;

		width: auto;
		min-width: 0;
	}
}