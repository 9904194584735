.m-scroll-list {
	$c: &;

	position: relative;


	&:before,
	&:after {
		content: '';

		position: absolute;
		right: 15px;
		bottom: 0;
		left: 0;

		height: 30px;

		background: linear-gradient(rgba(#2b1f0b,0), #2B1F0B);

		z-index: 1;
	}

	&:before {
		top: 0;
		bottom: auto;

		height: 20px;

		background: linear-gradient(#2B1F0B, rgba(#2b1f0b,0));
	}


	&__container {
		position: relative;

		display: block;
		height: 100%;
		margin: 0;
		padding: 10px 20px;

		overflow: hidden;
		overflow-y: scroll;
	}


	&.-c-horizontal {
		&:before,
		&:after {
			top: 0;
			right: 0;
			bottom: 20px;
			left: auto;

			width: 30px;
			height: auto;

			background: linear-gradient(to right, rgba(#33250d,0), #33250d);
		}

		&:before {
			right: auto;
			left: 0;

			background: linear-gradient(to right, #33250d, rgba(#33250d,0));
		}


		#{$c}__container {
			overflow: hidden;
			overflow-x: scroll;
		}
	}
}





/**
 * Scrollbar track colors in:
 *      helpers/_scrollbars.scss
 */