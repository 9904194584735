.m-tooltip {
	$c: &;

	position: relative;

	margin: 7px; // to prevent the pseudo borders from pushing outside screen
	padding: 1px;

	border: 2px solid #353028;
	border-radius: 9px;

	background-color: #000;


	&:before {
		content: '';

		position: absolute;
		top: -7px;
		left: -7px;
		right: -7px;
		bottom: -7px;

		border: 3px solid rgba(#000, 0.2);
		border-radius: 14px;
	}

	&:after {
		content: '';

		position: absolute;
		top: -4px;
		left: -4px;
		right: -4px;
		bottom: -4px;

		border: 2px solid rgba(#000, 0.5);
		border-radius: 11px;
	}


	&__outer {
		position: relative;

		width: 260px;
		min-height: 125px;

		border: 2px solid #6a4e32;
		border-radius: 6px;

		text-shadow: 1px 1px 0 #000;

		background-color: #332518;

		z-index: 1;


		&:before {
			content: '';

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			border: 3px solid #2b1f15;
			border-radius: 5px;
		}


		&.-c-padding {
			padding-top: 10px;
			padding-bottom: 15px;
		}
	}

	&__top {
		display: flex;
		flex-flow: row nowrap;
	}

	&__icon {
		position: relative;

		flex: 0 0 33px;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 33px;
		height: 30px;
		margin: 1px;

		border: 1px solid;
		border-radius: 3px 0 5px 0;

		z-index: 1;

		border-color: #73583c #43301e #43301e #73583c;
		color: #a68b69;
		background-color: #513b26;


		&:before {
			@extend %font-nm;

			font-size: 20px;
			text-shadow: 1px 1px 0 #000;
		}

		&:after {
			content: '';

			position: absolute;
			top: 0;
			right: -3px;
			bottom: -4px;
			left: 0;

			border-style: solid;
			border-width: 0 2px 2px 0;
			border-color: #2b1d11;
			border-radius: 0 0 7px 0;
		}
	}

	&__name {
		position: relative;

		flex: 1 0 0%;
		align-self: center;

		margin-top: 2px;
		padding: 5px 25px 4px 5px;

		font-size: 0.9rem;
		line-height: 1;
		font-weight: 700;
		text-align: center;
		text-shadow: 1px 1px 2px #000;
		word-wrap: break-word;

		color: #a68b69;

		overflow: hidden;


		&.-padding-center {
			padding-right: 38px;
		}

		&.-c-smaller {
			padding-top: 3px;
			padding-right: 20px;

			font-size: 0.8rem;
			text-shadow: 1px 1px 1px #000;
		}
	}

	&__divider {
		width: 100%;
		height: 15px;
		margin-top: 5px;

		background: url('#{$gfx-path}/themes/#{$theme}/shared/divider_tooltip_1.png') center center no-repeat;
	}

	&__levels {
		margin: 10px 0 5px 0;
		padding: 15px 15px 5px;

		background-color: #23180e;
	}

	&__stats-additional {
		position: relative;

		padding: 5px 15px 10px 25px;


		span {
			display: block;
			padding: 0 8px 0 0;

			font-size: 0.75rem;
			text-shadow: 1px 1px 1px #000;

			color: #e0a76a;

		}

		b {
			color: #f69024;
		}

		em {
			padding-left: 5px;

			font-size: 0.6rem;
			text-transform: uppercase;

			color: #b78c5e;
		}
	}

	&__stats-growth {
		padding: 15px 17px 10px 22px;
	}

	&__genjutsu {
		padding: 0 0 10px 0;
	}

	&__material-pic {
		position: relative;

		width: 68px;
		height: 68px;
		margin: 10px auto;

		border: 2px solid #483023;
		border-radius: 3px;


		&:before {
			content: '';

			position: absolute;
			top: -4px;
			left: -4px;
			right: -4px;
			bottom: -4px;

			border: 2px solid #231911;
			border-radius: 4px;
		}

		&:after {
			content: '';

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			border: 3px solid #1f150f;

			z-index: 1;
		}


		img {
			width: 64px;
			height: 64px;
		}
	}

	&__desc {
		padding: 5px 15px 2px 15px;

		font-size: 0.7rem;
		text-align: center;

		color: #876739;


		&.-c-indestructible {
			font-size: 0.6em;
			font-weight: 700;
			letter-spacing: 1px;
			text-transform: uppercase;

			color: #905a16;
		}

		&.-c-note {
			line-height: 1.1;

			color: #da7575;
		}
	}

	&__bottom {
		position: relative;

		display: flex;
		flex-flow: row wrap;
		justify-content: flex-end;
		align-items: center;

		margin: 2px 8px 5px 8px;


		span {
			padding-left: 13px;

			font-size: 0.65rem;
			text-transform: uppercase;

			color: #6b522d;


			i {
				font-size: 14px;
				line-height: 1;
				vertical-align: text-bottom;

				// color: #5b6269;
			}
		}

		b {
			font-size: 0.7rem;

			color: #bf9252;
		}

		em {
			color: #9c6c26;
		}
	}


	.-status-disabled {
		opacity: 0.3;
	}


	// component
	.c-overlay {
		border-radius: 6px;
	}
}



/**
 * Combinations section.
 */
.m-tooltip-combinations {
	padding: 0 10px;


	&__title {
		padding: 5px 10px 8px 10px;

		font-size: 0.65rem;
		text-align: center;
		text-transform: uppercase;

		color: #876739;
	}

	&__combos {
		display: flex;
		flex-flow: row wrap;

		margin-bottom: 10px;
		margin-left: 10px;
	}

	&__combo {
		display: flex;

		margin: 0 20px 10px 0;


		&.-c-wrap {
			flex-flow: row wrap;


			// component
			.c-card {
				margin-bottom: -4px;
			}
		}
	}


	// component
	.c-card {
		margin-right: -10px;

		z-index: 1;
	}
}



/**
 * Craftable items section.
 */
.m-tooltip-items {
	padding: 5px 20px 10px 20px;


	&__title {
		padding: 5px 10px 5px 10px;

		font-size: 0.65rem;
		text-align: center;
		text-transform: uppercase;

		color: #876739;
	}

	&__items {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
	}


	// component
	.c-item {
		width: 65px;

		margin: 3px;


		// position lone item in center
		&:first-child:nth-last-child(1) {
			margin: 3px auto;

			width: 110px;
		}

		// position 2 items in center
		&:first-child:nth-last-child(2),
		&:first-child:nth-last-child(2) ~ .c-item {
			margin: 3px auto;

			width: 95px;
		}
	}
}



/**
 * Primary stats section.
 */
.m-tooltip-stats {
	position: relative;

	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;

	padding: 5px 15px 5px 10px;


	&__stat {
		position: relative;

		flex: 0 0 33%;
		display: flex;
		align-items: center;

		width: 33%;
		height: 20px;

		text-shadow: 1px 1px 1px #000;


		span {
			width: 50%;
			padding-left: 4px;

			font-size: 0.65rem;
			text-transform: uppercase;

			color: #e0a76a;

			overflow: hidden;
		}

		b {
			width: 50%;

			font-size: 0.75rem;
			text-align: right;

			background-color: #332518;
			color: #e0a76a;
		}


		&.-nr-zero {

			span,
			b {
				text-shadow: 1px 1px 1px #120e08;

				color: #5d452b;
			}
		}
	}
}



/**
 * Growth-based stats, either by item or ninja level.
 */
.m-tooltip-growth {

	&__title {
		position: relative;

		display: flex;

		border-bottom: 1px solid #71493d;

		font-size: 0.6rem;
		line-height: 1.2;
		text-transform: uppercase;

		color: #cc7858; // default color if needed #a77441


		&:after {
			content: '';

			position: absolute;
			right: -1px;
			bottom: -2px;
			left: 1px;

			height: 1px;

			background-color: #23180e;
		}


		span {
			flex: 1 0 auto;


			&:last-child {
				margin-left: auto;

				text-align: right;
			}
		}
	}

	&__stats {
		padding: 4px 2px;
	}

	&__stat {
		position: relative;

		display: flex;
		padding: 0 3px 0 0;

		font-size: 0.75rem;
		line-height: 1.2;
		text-shadow: 1px 1px 1px #000;

		color: #e0a76a;


		span {
			flex: 1 0 auto;


			&:last-child {
				flex: 0 0 22px;

				width: 22px;
				margin-left: auto;

				font-size: 0.65rem;
				white-space: nowrap;
			}
		}

		b {
			font-weight: 600;

			color: #f69024;
		}
	}
}





/**
 * Genjutsu container.
 */
.m-tooltip-genjutsu {
	$c: &;

	position: relative;

	padding: 10px 0 0 0;


	&__requirements {
		position: relative;

		display: flex;
		flex-flow: row wrap;
		justify-content: flex-end;

		margin: -7px 10px -3px 20px;

		text-align: right;

		color: #cc5858;

		z-index: 1;


		span {
			margin: 1px;
			padding: 1px 4px;

			font-size: 0.6rem;
			text-transform: uppercase;

			border: 1px solid #713d3d;

			background-color: #331818;
		}

		b {
			color: #f98080;
		}
	}

	&__inner {
		position: relative;
	}

	&__top {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		width: 100%;
		height: 23px;

		background-color: #23180e;
	}

	&__cost {
		flex: 0 0 25px;

		width: 25px;
		margin-left: 15px;

		font-size: 0.75rem;
		text-align: right;

		color: #84befe;
	}

	&__icon {
		flex: 0 0 34px;

		width: 34px;

		font-size: 28px;
		text-align: center;
		text-shadow: 0 0 1px #000;

		color: #84befe;

		z-index: 2;


		&:before {
			@extend %font-nm;

			content: '\e800';
		}
	}

	&__name {
		flex-grow: 1;

		max-width: 55%;
		margin-right: 2px;
		margin-left: 3px;
		padding: 0 2px 0 2px;

		font-size: 0.75rem;

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		color: #81e3ff;
	}

	&__socket {
		flex: 0 0 auto;

		position: relative;

		display: flex;
		flex-flow: row wrap;
		justify-content: flex-end;

		height: 100%;
		margin: 0 1px 0 auto;

		text-align: right;

		color: #aaa;


		span {
			display: flex;
			align-items: center;

			padding: 1px 2px;

			font-size: 0.6rem;
			text-transform: uppercase;

			background-color: #1d160f;
		}

		i {
			margin: 0 1px 0 3px;

			font-size: 16px;
		}

		b {
			width: 16px;

			text-align: right;

			color: #ddd;
		}


		&.-c-capacity {
			i {
				font-size: 12px;

				color: #bf9252;
			}

			b {
				color: #e6c18c;
			}
		}
	}

	&__effects {
		position: relative;

		width: 100%;
		padding: 4px 10px 4px 25px;

		background-color: #2a1d10;
	}


	&.-type-pass {
		padding: 2px 0 0 30px;


		&:before {
			content: '';

			position: absolute;
			top: 0;
			left: 25px;

			width: 3px;
			height: 100%;

			background-color: #2a1d10;
		}


		#{$c}__top {
			height: 19px;
		}

		#{$c}__cost {
			margin-left: 0;

			font-size: 0.7rem;

			color: #a49dff
		}

		#{$c}__icon {
			width: 26px;

			font-size: 20px;

			color: #a49dff;
		}

		#{$c}__name {
			font-size: 0.7rem;

			color: #a49dff;
		}

		#{$c}__pass-icon {
			position: relative;
			top: -8px;

			margin: 0 10px 0 auto;

			font-size: 22px;
			text-shadow: 1px 1px 1px #000;

			color: #4e4a8a;


			&:before {
				@extend %font-nm;
			}


			// increase font-size of battlefield icon
			&.h-pass-genjutsu--battlefield {
				font-size: 30px;
			}
		}

		#{$c}__effects {
			padding: 4px 20px 1px 11px;
		}
	}

	&.-status-disabled {
		opacity: 1;


		#{$c}__inner {
			opacity: 0.3;
		}


		&.-type-pass {
			&:before {
				background-color: transparent;
			}
		}
	}
}



/**
 * Genjutsu effects section.
 */
.m-tooltip-effect {
	font-size: 0.65rem;
	line-height: 1;


	&__target {
		padding: 0 0 2px 0;

		color: #ff7979;
	}

	&__other {
		padding: 0 0 2px 0;

		color: #ef9775;


		span {
			position: relative;
			padding: 0 4px 0 0;

			white-space: nowrap;


			b {
				font-weight: 600;
			}


			&:after {
				content: '*';

				margin-left: 5px;

				color: #b17761;
			}

			&:last-child {
				&:after {
					content: '';
				}
			}
		}
	}

	&__duration {
		padding: 0 0 2px 0;

		color: #80afbb;
	}

	&__condition {
		padding: 0 5px 1px 0;

		color: #aac8d0;
	}

	&__stats {
		padding: 2px 0 5px 0;

		font-size: 0.7rem;
		line-height: 1.1;

		color: #00c6ff;


		span {
			position: relative;
			padding: 0 5px 0 0;

			white-space: nowrap;


			b {
				font-weight: 600;
			}


			&:after {
				content: '/';

				margin-left: 9px;

				color: #507883;
			}

			&:last-child {
				&:after {
					content: '';
				}
			}
		}


		&.-c-other {
			font-size: 0.6rem;

			color: #45b0fd;


			span {
				white-space: normal;


				b {
					font-size: 0.7rem;
				}
			}
		}
	}


	.-type-pass & {
		&__stats {
			font-size: 0.65rem;

			color: #9a93ff;


			span {
				&:after {
					color: #645083;
				}
			}
		}
	}
}



/**
 * Bloodline levels section.
 */
.m-tooltip-levels {
	position: relative;

	display: flex;
	width: 100%;


	&__level {
		position: relative;

		flex: 1 0 0%;
	}
}




/**
 * Rank requirements section.
 */
.m-tooltip-ranks {
	display: flex;

	padding: 30px 0;


	&__rank {
		position: relative;
	}

	&__pointer {
		position: absolute;
		top: -25px;
		right: 0;
		left: 0;

		margin: auto;

		font-size: 0.6rem;
		line-height: 1;
		text-align: center;

		color: #fd5a29;


		i {
			display: block;

			height: 18px;

			font-size: 20px;
		}


		&.-c-down {
			top: auto;
			bottom: -20px;

			color: #FDAA29;
		}
	}
}
