.m-item-list {
	display: flex;
	flex-flow: row wrap;


	&.-align-center {
		justify-content: center;
	}


	// component
	.c-item {
		width: 180px;
		margin: 5px;

		cursor: pointer;
	}
}