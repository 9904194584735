.m-section-list {
	$c: &;

	display: flex;
	flex-flow: row wrap;


	&__sub {
		display: flex;
		flex-flow: column nowrap;

		margin: 0 0 10px 30px;


		&.-type-row {
			flex-flow: row wrap;
		}
	}


	&.-type-column {
		flex-flow: column nowrap;


		#{$c}__sub {

			// ie11 margin fix
			> a {
				display: inline-flex;
			}


			// component
			.c-button {
				margin: 0 0 2px 0;
			}
		}


		// component
		.c-button {
			margin: 0 0 5px 0;
		}
	}

	&.-width-half {
		width: 50%;


		// component
		.c-button {
			width: 100%;
		}
	}

	// ie11 margin fix
	> a {
		display: inline-flex;
	}


	// component
	.c-button {
		margin: 0 10px 10px 0;
	}
}