.m-shop-list {
	padding: 0 2px 25px 2px;


	// component
	.c-shop-box {
		margin-bottom: 2px;
	}


	&.-list-abilities {

		// component
		.c-shop-box {
			padding: 10px 8px;
		}
	}
}