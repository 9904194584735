/**
 * Attributes container.
 */
.m-attributes-box {
	padding: 0 2px 0 2px;
}


/**
 * Attributes table section.
 */
.m-attributes-table {
	padding: 10px;


	table {
		position: relative;

		display: table;
		table-layout: fixed;
		border-collapse: collapse;
		border-spacing: 0;

		width: 100%;

		font-weight: bold;

		z-index: 1;
	}

	tr {
		position: relative;

		border-bottom: 1px solid #322011;


		&:last-child {
			border-bottom: 0;


			td {
				&:after {
					content: none;
				}
			}
		}
	}

	td {
		position: relative;

		width: 46px;
		height: 100%;

		font-size: 0.7rem;
		line-height: 1.9;
		text-align: center;
		text-shadow: 1px 1px 1px #000;

		color: #B78D68;


		&:after {
			content: '';

			position: absolute;
			bottom: -2px;
			left: 1px;

			width: 100%;
			height: 1px;

			background-color: #6F513C;
		}


		&.-c-name {
			width: 120px;
			padding-right: 20px;

			font-weight: normal;
			text-align: right;

			color: #EAA161;


			span {
				padding-right: 2px;

				display: block;

				text-overflow: ellipsis;
				white-space: nowrap;

				overflow: hidden;
			}
		}


		&.-c-effective {
			width: 60px;
			padding-right: 15px;

			text-align: right;

			color: #C97118;
		}


		&:nth-child(2) {
			color: #EAA161;
		}
	}
}