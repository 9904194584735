.m-button-list {
	position: relative;

	display: inline-flex;
	flex-flow: row nowrap;


	&__title {
		position: relative;
		top: 6px;

		min-width: 70px;
		padding: 0 10px 0 2px;

		font-size: 0.7rem;
		line-height: 1;
		text-align: right;
		text-transform: uppercase;
		text-shadow: 1px 1px 1px $shadow-color-strongest;

		color: #735e45
	}

	&__container {
		display: flex;
		flex-flow: row wrap;
	}


	// component
	.c-filter-button {
		margin-right: -3px;
		margin-bottom: -2px;
	}

	.c-item {
		margin-right: 10px;
		margin-bottom: 5px;
	}
}