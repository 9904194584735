.m-modal {
	$c: &;

	position: absolute;
	top: -700px;
	right: 0;
	left: 0;

	display: none;
	min-height: 550px;
	padding: 1px 1px;

	border: 1px solid #604e31;
	border-left-width: 0;

	background: #291d12;

	transition: top 0.75s ease 0s;
	z-index: 50;


	&:before,
	&:after {
		content: '';

		position: absolute;
		bottom: -16px;
		left: 0;

		width: 100%;
		height: 15px;

		background: linear-gradient(#1a130c, rgba(0,0,0,0));
	}

	&:after {
		top: -16px;
		bottom: auto;

		background: linear-gradient(rgba(0,0,0,0), #1a130c);
	}


	&__outer {
		position: relative;

		width: 100%;
		height: 100%;
		min-height: 550px;

		border: 3px solid #5a4630;


		&:before {
			content: '';

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			border: 2px solid #211709;//19110b;//271b0b;
		}
	}

	&__border {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 100%;


		&:before {
			content: '';

			position: absolute;
			top: -5px;
			right: -4px;

			width: 225px;
			height: 299px;

			background: url('#{$gfx-path}/themes/#{$theme}/shared/modal_bg_right_top.png') 0 0 no-repeat;
		}

		&:after {
			content: '';

			position: absolute;
			right: -4px;
			bottom: -4px;

			height: 226px;
			width: 269px;

			background: url('#{$gfx-path}/themes/#{$theme}/shared/modal_bg_right_bot.png') 0 0 no-repeat;
		}
	}

	&__inner {
		position: relative;

		min-height: inherit;

		z-index: 1;
	}

	&__padding {
		padding: 20px 100px 20px 10px;
	}

	&__bg {
		position: absolute;
		top: 25px;
		right: 50px;
		left: 80px;

		max-height: 550px;

		overflow: hidden;


		&:before {
			content: '';

			position: absolute;

			display: block;
			width: 100%;
			height: 100%;

			box-shadow: 0 0 50px 35px #34250E inset;
		}


		img {
			width: 100%;
		}


		&.-size-cover {
			top: 2px;
			right: 2px;
			left: 2px;

			max-height: 540px;


			&:before {
				box-shadow: none;

				background: url('#{$gfx-path}/themes/#{$theme}/shared/shadow_bg_opacity50.png') 0 0 no-repeat;
				background-size: 100% 100%;
			}
		}
	}


	&.-location-sidebar {
		position: fixed;
		top: auto;
		bottom: 68px;

		min-height: 300px;

		background: #34250e;

		z-index: 49;


		#{$c}__outer {
			&:before {
				border-color: #271b0b;
			}
		}

		#{$c}__bg {
			&:after {
				content: '';

				position: absolute;
				top: 0;
				left: 0;

				display: block;
				width: 100%;
				height: 100%;

				background-color: rgba(#34250E, 0.9);
			}
		}

		#{$c}__inner {
			min-height: 544px;
		}
	}

	&.-type-lineup {
		top: -720px;
	}

	&.-type-battle {
		top: -830px;

		min-height: 756px;


		#{$c}__outer {
			min-height: inherit;
		}
	}

	&.-location-top {
		border-top: 0;

		will-change: top;
	}

	&.-c-slide-down {
		top: -13px;

		transition: top 0.5s ease 0s;
	}

	&.-c-visible {
		top: 0;
	}

	&.-c-animations-disabled {
		* {
			transition: none !important;
			animation: none !important;
		}
	}


	// show overlay message
	&.-c-show-message {

		// hide overlay loader icon
		.c-overlay {
			@include c-overlay-active;


			&__loader {
				display: none;
			}
		}

		.c-overlay-message {
			display: block;
		}
	}



	@include for-phone-landscape-up {
		left: 1px;


		&__outer {
			border-left-width: 0;
		}

		&__padding {
			padding: 20px 100px 20px 90px;
		}

		&__bg {
			&.-size-cover {
				left: 40px;
			}
		}


		&.-location-sidebar {
			position: absolute;
			top: 420px;
			bottom: auto;
		}

		&.-c-slide-down {
			top: -48px;
		}
	}

	@include for-desktop-up {
		right: 1px;

		border-left-width: 1px;


		&.-location-top {
			#{$c}__outer {
				&:after {
					content: '';

					position: absolute;
					top: -3px;
					right: -9px;

					width: 4px;
					height: 100%;

					border-right: 3px solid #794c57;

					background: #140b02;
				}
			}
		}

		&.-c-border-ontop {
			#{$c}__border {
				&:before,
				&:after {
					z-index: 32;
				}
			}
		}
	}
}